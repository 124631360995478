import React from 'react'
import { Link } from 'gatsby'

import './index.scss'

export const ParentNavigator = ({ name, href }) => {
  return (
    <Link to={href} rel="tag" className="parent-navigator">
      <span><strong><u>📚 Series</u></strong></span>
      <span>{name}</span>
    </Link>
  )
}
