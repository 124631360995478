import React from 'react'

import './index.scss'
import { ParentNavigator } from './parent-navigator'
import { CategoryNavigator } from './category-navigator'

export const PostNavigator = ({ name, href, category }) => {
	return (
		<div className="post-navigator">
			{name ? <ParentNavigator name={name} href={href} /> : <div></div>}
			{category !== "hidden" ? <CategoryNavigator name={category} href={`?category=${category}`} /> : <div></div>}
		</div>
	)
}